(function() {

	// Fixer para o font size
	let els = document.querySelectorAll('[style]');

	els.forEach(el=> {
		let condicao = el.getAttribute('style').indexOf('font-size');

		if(condicao >= 0) {
			let fontSize = el.style.getPropertyValue('font-size');

			fontSize = parseFloat(fontSize.match(/\d/g, 'ha').join(''))

			fontSize = (fontSize / 16);

			el.style.setProperty('font-size', fontSize+'rem')
		}
	});

	// Fixer para as tabelas
	let tables = document.querySelectorAll('table');

	tables.forEach(table=> {
		let has = table.closest('.table-responsive');

		if(!has){
			table.insertAdjacentHTML('afterend', '<div class="table-responsive"></div>');

			let container = table.nextSibling;

			container.appendChild(table);
		}
	});
})();
