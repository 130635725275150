(()=> {
	const topo      = document.querySelector('.topo');
	const topoFaixa = document.querySelector('.topo__faixa');
	const active    = 'topo--fixed';

	_checkPosition();

	function _checkPosition() {
		topo.style.setProperty('--offset', `-${parseInt(topoFaixa.offsetHeight)}px`);

		if(window.scrollY >= topoFaixa.offsetHeight) {
			topo.classList.add(active);
		}else {
			topo.classList.remove(active);
		}

		requestAnimationFrame(_checkPosition);
	}
})();
