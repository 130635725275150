(()=> {
	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if(entry.isIntersecting) {
				let bg = entry.target.dataset.lazyBg || '';

				bg = JSON.stringify(bg);

				entry.target.style.setProperty('--lazy-bg', `url(${bg})`);
				entry.target.removeAttribute('data-lazy-bg');

				observer.unobserve(entry.target);
			}
		}, {
			rootMargin: '200px 200px 200px 200px'
		});
	});
	const lazyBgContainers = document.querySelectorAll('[data-lazy-bg]');

	lazyBgContainers.forEach(el => observer.observe(el));
})();
