const AnimatedViewport = new IntersectionObserver(entries=> {
	entries.forEach(entry=> {
		if(entry.isIntersecting) {
			let animationClasses = entry.target.dataset.animate;

			animationClasses = animationClasses.split(' ');

			animationClasses.forEach(classe => entry.target.classList.add(classe));

			AnimatedViewport.unobserve(entry.target);
		}
	})
});

(function() {
	const animatedElements = document.querySelectorAll('[data-animate]');

	animatedElements.forEach(el => AnimatedViewport.observe(el));
})();
