(()=> {
	const body   = document.body;
	const topo   = document.querySelector('.topo');
	const btns   = document.querySelectorAll('[data-toggle="search"]');
	const search = document.querySelector('.search');
	const input  = search.querySelector('.form-control');
	const active = 'search--shown';

	for(let i=0,btn; btn=btns[i]; i++) {
		btn.rippleCallback = function() {
			body.classList.add(active);
			search.classList.add(active);

			btn.setAttribute('aria-expanded', 'true');

			let backdrop = document.createElement('div');

			backdrop.classList.add('backdrop');
			topo.appendChild(backdrop);

			input.focus();

			backdrop.addEventListener('click', event => {
				event.preventDefault();

				body.classList.remove(active);
				search.classList.remove(active);

				btn.setAttribute('aria-expanded', 'false');

				backdrop.classList.add('hide');

				backdrop.addEventListener('animationend', event=> {
					topo.removeChild(backdrop);
				});
			});
		}
	}
})();
