(()=> {
	const body   = document.body;
	const topo   = document.querySelector('.topo');
	const btns   = document.querySelectorAll('[data-toggle="menu"]');
	const menu   = document.querySelector('.topo__main');
	const active = 'topo__main--shown';

	for(let i=0,btn; btn=btns[i]; i++) {
		btn.rippleCallback = function() {
			body.classList.add(active);
			menu.classList.add(active);

			btn.setAttribute('aria-expanded', 'true');

			let backdrop = document.createElement('div');

			backdrop.classList.add('backdrop');
			topo.appendChild(backdrop);

			backdrop.addEventListener('click', event => {
				event.preventDefault();

				body.classList.remove(active);
				menu.classList.remove(active);

				btn.setAttribute('aria-expanded', 'false');

				backdrop.classList.add('hide');

				backdrop.addEventListener('animationend', event=> {
					topo.removeChild(backdrop);
				});
			});
		}
	}
})();
