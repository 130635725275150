function btnRipple(btn) {

	if(btn.tagName === 'a' || btn.tagName === 'A') {
		if(btn.getAttribute('href') != '#') {
			let href 	= btn.getAttribute('href');
			let target 	= btn.getAttribute('target');

			btn.rippleCallback = function() {
				let a = document.createElement('a');

				a.href 	 = href;

				if(target) a.target = target;

				document.body.appendChild(a);

				a.addEventListener('click', function() {
					document.body.removeChild(a);
				})

				a.click();
			}
		}
	}

	btn.addEventListener('click', function (event) {
		event.preventDefault();

		var ripple =  document.createElement('div');

		ripple.classList.add('ripple');
		ripple.style.setProperty('--x', event.offsetX+'px');
		ripple.style.setProperty('--y', event.offsetY+'px');
		ripple.addEventListener('animationend', function() {
			btn.removeChild(ripple);

			if(btn.rippleCallback) btn.rippleCallback();
		});

		btn.appendChild(ripple);
	});
}

const btnsRiple = document.querySelectorAll('.btn-ripple');

for(var i=0, btn; btn=btnsRiple[i]; i++) {
	btnRipple(btn);
}
