(function () {
	var links 	   = $('.faq__link');
	var container  = $('.faq__container');
	var active     = 'faq__category--active';

	links.each(function() {
		var link = $(this);
		var url  = link.attr('href');

		link.click(function(e) {
			e.preventDefault();

			if(link.hasClass(active)) return false;

			var startTime = new Date().getTime();

			links.removeClass(active);
			link.addClass(active);

			if(link.data('faq.cache')) {
				container.html(loader());

				setTimeout(function() {
					render(link.data('faq.cache'));
				}, 600)

				return false;
			}

			$.ajax({
				url: url,
				beforeSend: function() {
					container.html(loader())
				}
			})
			.done(function(data) {
				var columns         = 2;
				var itensPorColuna  = Math.ceil(data.length / columns);
				var content         = '';
				var startPosition   = 0;
				var count           = 0;

				for(var i=0;i<columns;i++) {
					var fatia = data.slice(startPosition, startPosition+itensPorColuna);

					content += `<div class="col-md-6">${fatia.map((item, index)=> {
						var template = `<div class="faq__item" data-animate="flipInX animated">
							<button type="button" data-toggle="collapse" data-target="#resposta-${count}" aria-expanded="false" class="btn">${item.pergunta}</button>
							<div class="faq__resposta collapse" data-parent="#faq" id="resposta-${count}">${item.resposta}</div>
						</div>`;

						count++

						return template;
					}).join('\n')}</div>`

					startPosition = startPosition+itensPorColuna
				}

				var currentTime = new Date().getTime();
				var timeToLoad  = currentTime - startTime;

				link.data('faq.cache', content);

				if(timeToLoad >= 600) {

					render(content);

				}else {
					setTimeout(function() {

						render(content);

					}, (600 - timeToLoad));
				}

			});
		});
	});

	function render(content) {
		container.html(content);

		container.find('[data-animate]').each(function() {
			AnimatedViewport.observe(this);
		});;
	}

	function loader() {
		var dots = '';

		for(let i=0;i<5; i++) {
			dots += `<div class="dot" style="--i: ${i}"></div>`;
		}
		return `<div class="loader" style="--n: 5;">${dots}</div>`;
	}

})();
