(function() {
	var servicos = document.querySelectorAll('.servicos__produtos .servico__content');

	servicos.forEach(servico => {
		let clone = servico.cloneNode(true);

		clone.classList.add('clone');

		servico.parentNode.appendChild(clone);
	});
})();
